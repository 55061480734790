<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <OrganizationNav />
          </div>
          <div class="col-12 col-md-9">
            <h4>Permissions for {{ name }}</h4>
            <div 
              class="card border-0 shadow-sm mb-4 pt-2"
              v-if="role.slug !== 'admin' && role.slug !== 'super_admin'"
            >
              <div
                class="form-group col-md-12 required"
              >
                <label for="role_name" class="control-label text-capitalize"
                  >Name:</label
                >
                <input
                  id="role_name"
                  v-model="role.name"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                />
              </div>
            </div>
            <div class="card border-0 shadow-sm mb-4">
              <div
                v-if="Object.keys(permissions).length !== 0"
                class="card-body"
              >
                <div
                  class="row"
                  v-for="permission in permissions"
                  :key="permission.id"
                >
                  <div class="col-12">
                    <h4 class="h4 border-bottom pb-2">
                      {{ permission.name }}
                      <div class="custom-control custom-switch float-right">
                        <input
                          :id="permission.id + 'Visibility'"
                          :checked="hasPermission(permission.id)"
                          @input="
                            updatePermission(permission, $event.target.checked)
                          "
                          type="checkbox"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          :for="permission.id + 'Visibility'"
                          >&nbsp;</label
                        >
                      </div>
                    </h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      type="submit"
                      class="btn btn-secondary float-right"
                      :disabled="loading"
                      @click="saveRole"
                    >
                      <Spinner v-if="loading" />
                      Save role
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="card-body">
                <p class="text-muted">
                  This permission list is empty: You're seeing this message either
                  because: 1. There are no relevant permissions to show for the
                  created role 2. Automation.app is (unlikely) down. To resolve this, try
                  reloading the page or contact
                  <a href="mailto:nerds@automation.app">nerds@automation.app</a
                  >.
                </p>
                <div class="p-4 rounded border-zip text-center">
                  <p class="mb-0">
                    No role permissions found!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import Spinner from "@/components/UI/Spinner.vue";

export default {
  name: "RolesList",
  components: {
    Default,
    OrganizationNav,
    Spinner,
  },
  async beforeRouteEnter(to, from, next) {
    const roleId = to.params.roleId;
    let uri = `/organization/roles/${roleId}`;
    const permissions = await jsonAPI.get("/organization/permissions");
    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.roleId = roleId;
        vm.role = response.data;
        vm.permissions = permissions.data;
      });
    });
  },
  computed: {
    name() {
      return this.role.name || "";
    },
  },
  data() {
    return {
      layout: "Default",
      roleId: "",
      role: [],
      permissions: [],
      loading: false,
      role_name: ""
    };
  },
  methods: {
    hasPermission(permissionId) {
      return (
        this.role.permissions.filter((permission) => {
          return permission.id === permissionId;
        }).length > 0
      );
    },
    updatePermission(permission, value) {
      if (value) {
        this.role.permissions.push(permission);
      } else {
        this.role.permissions = this.role.permissions.filter((perm) => {
          return perm.id !== permission.id;
        });
      }
    },
    saveRole() {
      this.loading = true;
      this.role.slug = this.role.name.replaceAll(" ", "-").toLowerCase();
      let uri = `/organization/roles/${this.roleId}`;
      jsonAPI
        .put(uri, this.role)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: "Permissions updated",
            type: "success",
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          window.Bus.$emit("flash-message", {
            text: "Error updating permissions",
            type: "error",
          });
        });
    },
  },
};
</script>
